import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { PlusCircleIcon } from '@heroicons/react/outline'
import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
const CREATE_ORG = loader('./createOrg.graphql')

export default function DemoOrgModal({ open, setOpen, account, refetch }) {
  const [name, setName] = useState(null)
  const [demo_data, setDemoData] = useState(false)

  const [createDemoOrg, { data, called, loading, error }] = useMutation(CREATE_ORG, {
    update: () => refetch()
  })

  const cancelButtonRef = useRef(null)

  const handleSubmit = async () => {
    await createDemoOrg({
      variables: {
        account_id: account.id,
        name,
        demo_data
      }
    })
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PlusCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Add organization
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        If you choose to add demo data you will have an organization with a team, members and a configured
                        position with evaluations and candidates. Otherwise just an empty org with yourself in it (NB! If you
                        are not an account guest you will take up a seat!).
                      </p>
                    </div>

                    {called && !loading ? (
                      <div className="mt-2 bg-gray-100 rounded px-4 py-4 text-gray-700">
                        {error ? (
                          error
                        ) : (
                          <a
                            href={`${process.env.REACT_APP_REDIRECT_URL}/dashboard?org_id=${data.createDemoOrg.id}`}
                            target={'_blank'}
                            rel="noreferrer"
                          >
                            {process.env.REACT_APP_REDIRECT_URL}
                            /dashboard?org_id={data.createDemoOrg.id}
                          </a>
                        )}
                      </div>
                    ) : (
                      <div className="mt-10 mb-10">
                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                          Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          onChange={(e) => setName(e.target.value)}
                        />
                        <div className="flex  mt-10 mb-10">
                          <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                            Add demo data?
                          </label>
                          <input
                            type="checkbox"
                            name="demo_data"
                            id="demo_data"
                            className="ml-1 focus:ring-indigo-500 focus:border-indigo-500 block  shadow-sm sm:text-sm border-gray-300 rounded-md"
                            onChange={(e) => setDemoData(!demo_data)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                {!called && !loading && (
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm "
                    onClick={handleSubmit}
                    disabled={loading || !name || name === ''}
                  >
                    {loading ? 'Loading...' : 'Add'}
                  </button>
                )}

                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm "
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  {called && !loading ? 'Done' : 'Cancel'}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
