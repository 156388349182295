import Table from './Table'

export default function List({ data, title, link }) {
  return (
    <div className="mt-10 bg-white shadow overflow-hidden sm:rounded-lg cursor-pointer">
      <div className="px-4 py-5 sm:px-6 flex flex-row">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {title}
        </h3>
      </div>
      <Table data={data} link={link} />
    </div>
  )
}
