import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import Table from './Table'

import { loader } from 'graphql.macro'
const USER_INVITES = loader('./UserInvitesSent.graphql')

export default function Organizations() {
  const { id: user_id } = useParams()

  const { data, loading } = useQuery(USER_INVITES, {
    variables: {
      user_id: parseInt(user_id, 10)
    }
  })
  if (loading) return null
  const { invites_sent } = data.user

  return (
    <div className="mt-10 bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6 flex flex-row">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Invites sent</h3>
      </div>
      <Table data={invites_sent} />
    </div>
  )
}
