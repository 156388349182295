import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import dateformat from 'dateformat'
import { AvatarWithNameAndEmail } from 'components'
import { loader } from 'graphql.macro'
import Members from './Members'
import Invites from './Invites'
const TEAM = loader('./team.graphql')

export default function User() {
  const { id: team_id } = useParams()
  const history = useHistory()

  const { data, loading } = useQuery(TEAM, {
    variables: {
      team_id: parseInt(team_id, 10)
    }
  })

  if (loading) return null
  const { id, name, created_at, created_by, members, invites, organization } = data.team

  const colors = {
    0: 'blue',
    1: 'green',
    2: 'purple',
    3: 'yellow',
    4: 'red'
  }
  const color = (name) => `bg-${colors[Array.from(name.charCodeAt(0).toString()).map(Number)[0] % 5]}-500`

  return (
    <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">ID</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{id}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{name}</dd>
            </div>

            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Created at</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{dateformat(created_at, 'longDate')}</dd>
            </div>

            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Created by</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {created_by && <AvatarWithNameAndEmail person={created_by} />}
              </dd>
            </div>

            <div
              className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 cursor-pointer hover:bg-gray-100"
              onClick={() => history.push(`/organization/${organization.id}`)}
            >
              <dt className="text-sm font-medium text-gray-500">Organization</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className=" flex items-center">
                  <div
                    className={`
                      h-7
                      w-7
                      flex
                      items-center
                      justify-center
                      rounded
                      uppercase
                      mr-2
                      text-white
                      font-medium
                      ${color(organization.name)}
                      `}
                  >
                    {organization.name[0]}
                  </div>
                  {organization.name}
                </div>
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <Members members={members} />
      <Invites invites={invites} />
    </main>
  )
}
