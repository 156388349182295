import React, { useState, useEffect } from 'react'
import { SearchIcon } from '@heroicons/react/solid'
import useDebounce from 'utils/debounce'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import Table from './Table'
const USERS = loader('./users.graphql')

export default function Users() {
  const [searchTerm, setSearchTerm] = useState(null)
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const [order, setOrder] = useState('reverse:')
  const [limit, setLimit] = useState(50)
  const [offset, setOffset] = useState(0)
  const [orderColumn, setOrderColumn] = useState('registered')

  const { data, refetch } = useQuery(USERS, {
    variables: {
      limit,
      offset,
      order: `${order}${orderColumn}`
    },
    notifyOnNetworkStatusChange: true
  })

  useEffect(() => {
    refetch({
      limit,
      offset,
      order: `${order}${orderColumn}`,
      search: debouncedSearchTerm
    })
  }, [debouncedSearchTerm, refetch, order, limit, offset, orderColumn])

  return (
    <>
      <header className="bg-white shadow">
        <div className="flex items-stretch max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <div className="relative text-gray-600">
            <SearchIcon className="absolute left-4 top-2 -ml-1 mr-2 h-5 w-5 text-gray-500" />
            <input
              className="w-200 inline-flex items-center px-4 py-2 border border-gray-300 bg-white pl-10 rounded-md text-sm focus:outline-none"
              type="search"
              name="search"
              placeholder="Search"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {data && (
            <Table
              data={data}
              order={order}
              setOrder={setOrder}
              limit={limit}
              setLimit={setLimit}
              offset={offset}
              setOffset={setOffset}
              orderColumn={orderColumn}
              setOrderColumn={setOrderColumn}
            />
          )}
        </div>
      </main>
    </>
  )
}
