import { AvatarWithNameAndEmail } from 'components'
import { useHistory } from 'react-router-dom'

export default function Invites({ invites }) {
  const history = useHistory()
  return (
    <div className="mt-5 flex flex-col">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Pending nvites
        </h3>
      </div>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Invited user
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Invite link
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Invited by
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {invites.map((invite, index) => (
                  <tr
                    key={`invite-${invite.id}`}
                    className=" cursor-pointer hover:bg-gray-50 transition ease-in-out duration-150"
                    onClick={() =>
                      history.push(`/invite/${invite.id}`)
                    }
                  >
                    <td className="px-6 py-4 whitespace-nowrap  text-xs font-semibold text-gray-600">
                      {invite.id}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <AvatarWithNameAndEmail
                        person={invite.invited_user || invite}
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap  text-xs font-semibold text-gray-600">
                      {`https://app.wisnio.com/invite/${invite.secret}`}
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <AvatarWithNameAndEmail
                        person={invite.inviter}
                      />
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap"></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
