import { useState } from 'react'
import { PlusIcon } from '@heroicons/react/solid'
import AddUserModal from './AddUserModal'

const AddUser = ({ org_id }) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <button
        type="button"
        className="mt-5 mr-5 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={() => setOpen(true)}
      >
        <PlusIcon
          className="-ml-1 mr-2 h-5 w-5 text-gray-500"
          aria-hidden="true"
        />
        Add user
      </button>
      {open && (
        <AddUserModal org_id={org_id} open={open} setOpen={setOpen} />
      )}
    </>
  )
}

export default AddUser
