import React, { useContext, createContext } from 'react'
import Cookies from 'js-cookie'
import jwt from 'jsonwebtoken'


function useProvideAuth() {

  return {
    logout: () => {
      Cookies.remove('jwt')
      window.location = '/'
    },
    user: jwt.decode(Cookies.get('jwt'))
  };
}

const authContext = createContext();

const AuthProvider = ({ children }) => {
  const auth = useProvideAuth();
  if (!auth.user) window.location = '/auth'
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export const useAuth = () =>  {
  return useContext(authContext);
}

export default AuthProvider
