import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import Table from './Table'

import { loader } from 'graphql.macro'
const USER_ORGANIZATIONS = loader('./UserOrganizations.graphql')

export default function Organizations() {
  const { id: user_id } = useParams()

  const { data, loading } = useQuery(USER_ORGANIZATIONS, {
    variables: {
      user_id: parseInt(user_id, 10)
    }
  })
  if (loading) return null
  const { organizations } = data.user

  return (
    <div className="mt-10 bg-white shadow overflow-hidden sm:rounded-lg ">
      <div className="px-4 py-5 sm:px-6 flex flex-row">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Organizations</h3>
      </div>
      <Table data={organizations} />
    </div>
  )
}
