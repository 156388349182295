import React from 'react'
import { useTable } from 'react-table'
import dateformat from 'dateformat'
import { useHistory } from 'react-router-dom'
import { CalendarIcon } from '@heroicons/react/outline'

const IDCell = (data) => {
  return <div className="flex items-center text-xs font-semibold text-gray-600">{data.cell.value}</div>
}

const StateCell = (data) => {
  const colors = {
    pending: 'bg-yellow-400',
    accepted: 'bg-green-400'
  }
  return (
    <div className="flex items-center ">
      <div
        className={` px-3
          py-1
          rounded-full
          text-xs
          text-gray-100
          ${colors[data.cell.value]}
        `}
      >
        {data.cell.value}
      </div>
    </div>
  )
}

const DateCell = (d) => {
  return (
    <div className=" flex-1 flex items-center text-xs font-semibold text-gray-600">
      <CalendarIcon className="mr-2 flex-shrink-0 h-5 w-5 text-gray-300" />{' '}
      {d.cell.value && dateformat(d.cell.value, 'longDate')}
    </div>
  )
}

const EmailCell = ({ value }) => {
  return (
    <div
      className="
      flex
      items-center"
    >
      {value}
    </div>
  )
}
const AccountNameCell = (data) => {
  return (
    <div
      className="
      flex
      items-center"
    >
      {data?.cell?.value?.name}
    </div>
  )
}

const HeaderCell = ({ text, selector, setOrderColumn, setOrder, order, orderColumn }) => {
  return (
    <div
      className="
        inline-flex
        items-center
        justify-center
      "
    >
      {text}
    </div>
  )
}

export default function Table({ data, order, setOrder, limit, setLimit, offset, setOffset, orderColumn, setOrderColumn }) {
  const history = useHistory()
  const columns = React.useMemo(
    () => [
      {
        Header: HeaderCell({
          text: 'id',
          selector: 'id',
          setOrderColumn,
          setOrder,
          order,
          orderColumn
        }),
        accessor: 'id',
        Cell: IDCell
      },
      {
        Header: HeaderCell({
          text: 'email',
          selector: 'email',
          setOrderColumn,
          setOrder,
          order,
          orderColumn
        }),
        accessor: 'email',
        Cell: EmailCell
      },

      {
        Header: HeaderCell({
          text: 'account'
        }),
        accessor: 'account',
        Cell: AccountNameCell
      },
      {
        Header: HeaderCell({
          text: 'created at',
          selector: 'created_at',
          setOrderColumn,
          setOrder,
          order,
          orderColumn
        }),
        accessor: 'created_at',
        Cell: DateCell
      },
      {
        Header: HeaderCell({
          text: 'state',
          selector: 'state',
          setOrderColumn,
          setOrder,
          order,
          orderColumn
        }),
        accessor: 'state',
        Cell: StateCell
      }
    ],
    [order, orderColumn, setOrder, setOrderColumn]
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: [...data].sort((a, b) => {
      if (!b.created_at) return -1
      return new Date(b.created_at) - new Date(a.created_at)
    })
  })

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200" {...getTableProps()}>
              <thead className="bg-gray-50">
                {
                  // Loop over the header rows
                  headerGroups.map((headerGroup) => (
                    // Apply the header row props
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {
                        // Loop over the headers in each row
                        headerGroup.headers.map((column) => (
                          // Apply the header cell props
                          <th
                            {...column.getHeaderProps()}
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {
                              // Render the header
                              column.render('Header')
                            }
                          </th>
                        ))
                      }
                    </tr>
                  ))
                }
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 text-sm" {...getTableBodyProps()}>
                {
                  // Loop over the table rows
                  rows.map((row) => {
                    // Prepare the row for display
                    prepareRow(row)

                    return (
                      // Apply the row props
                      <tr
                        {...row.getRowProps()}
                        className="hover:bg-gray-50 cursor-pointer"
                        onClick={() => history.push(`/invite/${row.original.id}`)}
                      >
                        {
                          // Loop over the rows cells
                          row.cells.map((cell) => {
                            // Apply the cell props

                            return (
                              <td {...cell.getCellProps()} className="px-6 py-4 whitespace-nowrap">
                                {
                                  // Render the cell contents
                                  cell.render('Cell')
                                }
                              </td>
                            )
                          })
                        }
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
