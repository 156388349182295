import { AtSymbolIcon } from '@heroicons/react/outline'

export default function AvatarWithNameAndEmail({ person }) {
  return (
    <div className="flex items-center">
      <div className="flex-shrink-0 h-10 w-10">
        {person.avatar_url ? (
          <img
            className="h-10 w-10 rounded-full"
            src={person.avatar_url}
            alt=""
          />
        ) : person.initials ? (
          <div
            className="h-10 w-10 bg-gray-100 flex items-center justify-center rounded-full"
            src={person.avatar_url}
            alt=""
          >
            {person.initials}
          </div>
        ) : (
          <div
            className="h-10 w-10 bg-gray-100 flex items-center justify-center rounded-full"
            src={person.avatar_url}
            alt=""
          >
            <AtSymbolIcon className="h-5 w-5" />
          </div>
        )}
      </div>
      <div className="ml-4">
        <div className="text-sm font-medium text-gray-900">
          {person.name}
        </div>
        <div className="text-sm text-gray-500">{person.email}</div>
      </div>
    </div>
  )
}
