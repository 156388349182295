import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { PlusCircleIcon } from '@heroicons/react/outline'
import { useMutation, useLazyQuery } from '@apollo/client'
import useDebounce from 'utils/debounce'
import { AvatarWithNameAndEmail, Select } from 'components'
import roles from 'config/roles'

import { loader } from 'graphql.macro'
const SEARCH_USERS = loader('./searchUsers.graphql')
const ADD_USER_TO_ORGANIZATION = loader('./addUserToOrganization.graphql')

export default function DemoOrgModal({ open, setOpen, org_id }) {
  const [searchTerm, setSearchTerm] = useState(null)
  const [selection, setSelection] = useState(null)
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const [searchUsers, { data }] = useLazyQuery(SEARCH_USERS, {
    notifyOnNetworkStatusChange: true
  })

  const [addUserToOrganization] = useMutation(ADD_USER_TO_ORGANIZATION)

  useEffect(() => {
    if (debouncedSearchTerm) {
      searchUsers({
        variables: {
          search: debouncedSearchTerm,
          exclude_org_id: org_id
        }
      })
    }
  }, [debouncedSearchTerm, org_id, searchUsers])

  const cancelButtonRef = useRef(null)

  const handleSubmit = async () => {
    await addUserToOrganization({
      variables: {
        org_id,
        user_id: selection.id,
        set_as_guest: selection.set_as_guest,
        role: selection.role,
        set_default_org: selection.set_default_org
      }
    })
    setOpen(false)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 " initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PlusCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 mr-12 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Add a user to this organization
                    </Dialog.Title>

                    <div className="mt-10 mb-10 ">
                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                        Search
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        value={searchTerm || undefined}
                        onChange={(e) => {
                          setSearchTerm(e.target.value)
                        }}
                      />
                    </div>
                    <div>
                      {data?.users &&
                        debouncedSearchTerm &&
                        data.users.map((user, key) => (
                          <div
                            key={key}
                            className={`px-2 py-2 whitespace-nowrap  text-xs rounded font-semibold text-gray-600 hover:bg-gray-200 divide-solid cursor-pointer`}
                            onClick={() => {
                              setSelection(user)
                              setSearchTerm(null)
                            }}
                          >
                            <AvatarWithNameAndEmail person={user} />
                          </div>
                        ))}
                      {selection && (
                        <div className="border-t pt-10">
                          <AvatarWithNameAndEmail person={selection} />
                          <div className="mt-10">
                            <Select
                              title={selection.role || 'Choose a role'}
                              items={roles.map((role) => ({
                                title: role
                              }))}
                              handleChange={(role) => setSelection({ ...selection, role })}
                            />
                          </div>
                          <div className="mt-3">
                            <input
                              name={`${selection.id}-set_as_guest`}
                              type="checkbox"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mr-2 ml-2"
                              defaultChecked={selection.set_as_guest}
                              onChange={() => {
                                setSelection({
                                  ...selection,
                                  set_as_guest: !selection.set_as_guest
                                })
                              }}
                            />
                            <label className="mr-4">Is guest</label>
                          </div>
                          <div className="mt-3">
                            <input
                              name={`${selection.id}-set-default-orge`}
                              type="checkbox"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mr-2 ml-2"
                              defaultChecked={selection.set_default_org}
                              onChange={() => {
                                setSelection({
                                  ...selection,
                                  set_default_org: !selection.set_default_org
                                })
                              }}
                            />
                            <label className="mr-4">Set as default organization</label>
                          </div>
                          <div className="mt-10 flex-1">
                            <button
                              type="button"
                              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mr-3 sm:w-auto sm:text-sm"
                              onClick={handleSubmit}
                            >
                              Add to organization
                            </button>
                            <button
                              type="button"
                              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                              onClick={() => {
                                setSelection(null)
                                setOpen(false)
                              }}
                              ref={cancelButtonRef}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
