import React from 'react'
import { useTable } from 'react-table'
import dateformat from 'dateformat'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import { CalendarIcon } from '@heroicons/react/outline'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'
import { AvatarWithNameAndEmail, Pagination } from 'components'

const IDCell = (data) => {
  return <div className="flex items-center text-xs font-semibold text-gray-600">{data.cell.value}</div>
}

const BooleanCell = (data) => {
  return (
    <div className="flex items-center text-xs font-semibold text-gray-600">
      <input
        type="checkbox"
        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
        defaultChecked={data.cell.value}
        disabled
      />
      {data.cell.value}
    </div>
  )
}

const DateCell = (d) => {
  return (
    <div className=" flex-1 flex items-center text-xs font-semibold text-gray-600">
      <CalendarIcon className="mr-2 flex-shrink-0 h-5 w-5 text-gray-300" /> {dateformat(d.cell.value, 'longDate')}
    </div>
  )
}

const NameCell = (data) => {
  const colors = {
    0: 'blue',
    1: 'green',
    2: 'purple',
    3: 'yellow',
    4: 'red'
  }
  const color = `bg-${colors[Array.from(data.cell.value.charCodeAt(0).toString()).map(Number)[0] % 5]}-500`
  return (
    <div
      className="
      flex
      items-center"
    >
      <div
        className={`
        h-7
        w-7
        flex
        items-center
        justify-center
        rounded
        uppercase
        mr-2
        text-white
        font-medium
        ${color}
        `}
      >
        {data.cell.value[0]}
      </div>
      {data.cell.value}
    </div>
  )
}

const HeaderCell = ({ text, selector, setOrderColumn, setOrder, order, orderColumn, noOrdering }) => {
  return (
    <div
      className="
        inline-flex
        items-center
        justify-center
      "
    >
      {!noOrdering && (
        <div className="flex flex-col">
          <button
            onClick={() => {
              setOrderColumn(selector)
              setOrder('reverse:')
            }}
          >
            <ChevronUpIcon
              className={classNames(
                'h-5',
                'w-5',
                '-mb-2',
                orderColumn === selector && order === 'reverse:' && 'text-yellow-500'
              )}
            />
          </button>
          <button
            onClick={() => {
              setOrderColumn(selector)
              setOrder('')
            }}
          >
            <ChevronDownIcon
              className={classNames('h-5', 'w-5', orderColumn === selector && order === '' && 'text-yellow-500')}
            />
          </button>
        </div>
      )}

      {text}
    </div>
  )
}

const CreatorCell = (data) => {
  return (
    <div className=" flex-1 flex items-center text-xs font-semibold text-gray-600">
      {data.value && <AvatarWithNameAndEmail person={data.value} />}
    </div>
  )
}

export default function Table({ data, order, setOrder, limit, setLimit, offset, setOffset, orderColumn, setOrderColumn }) {
  const history = useHistory()
  const columns = React.useMemo(
    () => [
      {
        Header: HeaderCell({
          text: 'id',
          selector: 'id',
          setOrderColumn,
          setOrder,
          order,
          orderColumn
        }),
        accessor: 'id',
        Cell: IDCell
      },
      {
        Header: HeaderCell({
          text: 'name',
          selector: 'name',
          setOrderColumn,
          setOrder,
          order,
          orderColumn
        }),
        accessor: 'name',
        Cell: NameCell
      },
      {
        Header: HeaderCell({
          text: 'created by',
          selector: 'created_by',
          setOrderColumn,
          setOrder,
          order,
          orderColumn,
          noOrdering: true
        }),
        accessor: 'created_by',
        Cell: CreatorCell
      },
      {
        Header: HeaderCell({
          text: 'created at',
          selector: 'created_at',
          setOrderColumn,
          setOrder,
          order,
          orderColumn
        }),
        accessor: 'created_at',
        Cell: DateCell
      },
      {
        Header: HeaderCell({
          text: 'is demo',
          selector: 'is_demo',
          noOrdering: true,
          setOrderColumn,
          setOrder,
          order,
          orderColumn
        }),
        accessor: 'is_demo',
        Cell: BooleanCell
      }
    ],
    [order, orderColumn, setOrder, setOrderColumn]
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: data.teams })

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200" {...getTableProps()}>
              <thead className="bg-gray-50">
                {
                  // Loop over the header rows
                  headerGroups.map((headerGroup) => (
                    // Apply the header row props
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {
                        // Loop over the headers in each row
                        headerGroup.headers.map((column) => (
                          // Apply the header cell props
                          <th
                            {...column.getHeaderProps()}
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {
                              // Render the header
                              column.render('Header')
                            }
                          </th>
                        ))
                      }
                    </tr>
                  ))
                }
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 text-sm" {...getTableBodyProps()}>
                {
                  // Loop over the table rows
                  rows.map((row) => {
                    // Prepare the row for display
                    prepareRow(row)

                    return (
                      // Apply the row props
                      <tr
                        {...row.getRowProps()}
                        className="hover:bg-gray-50 cursor-pointer"
                        onClick={() => history.push(`team/${row.original.id}`)}
                      >
                        {
                          // Loop over the rows cells
                          row.cells.map((cell) => {
                            // Apply the cell props

                            return (
                              <td {...cell.getCellProps()} className="px-6 py-4 whitespace-nowrap">
                                {
                                  // Render the cell contents
                                  cell.render('Cell')
                                }
                              </td>
                            )
                          })
                        }
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
        <Pagination count={data.count} limit={limit} setLimit={setLimit} offset={offset} setOffset={setOffset} />
      </div>
    </div>
  )
}
