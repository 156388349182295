import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import dateformat from 'dateformat'
import { AvatarWithNameAndEmail } from 'components'
import { loader } from 'graphql.macro'
import List from './List'
const INVITE = loader('./invite.graphql')

export default function User() {
  const { id: invite_id } = useParams()

  const { data, loading } = useQuery(INVITE, {
    variables: {
      inviteId: parseInt(invite_id, 10)
    }
  })

  if (loading) return null

  const { id, email, created_at, inviter, state, secret, accepted_at, organizations, teams, positions } = data.invite

  const colors = {
    pending: 'text-yellow-400',
    accepted: 'text-green-400'
  }

  return (
    <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">ID</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{id}</dd>
            </div>

            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Email</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{email}</dd>
            </div>

            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Link</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {`https://app.wisnio.com/invite/${secret}`}
              </dd>
            </div>

            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Created at</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{dateformat(created_at, 'longDate')}</dd>
            </div>

            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Created by</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <AvatarWithNameAndEmail person={inviter} />
              </dd>
            </div>

            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">State</dt>
              <dd className={`flex flex-row px-3 py-1 rounded-full text-xs font-bold ${colors[state]}`}>
                {state === 'accepted' ? `Accepted on ${dateformat(accepted_at, 'longDate')}` : 'Pending'}
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <List data={organizations} title="Organizations" link="organization" />
      <List data={teams} title="Teams" link="team" />
      <List data={positions} title="Positions" link="position" />
    </main>
  )
}
