import { useMutation } from '@apollo/client'
import classNames from 'classnames'
import { AvatarWithNameAndEmail, Select } from 'components'
import { accountRoles as roles } from 'config/roles'

import { loader } from 'graphql.macro'
const TOGGLE_IS_ACCOUNT_GUEST = loader('./toggleIsAccountGuest.graphql')
const UPDATE_ROLE_IN_WORKSPACE = loader('./updateRoleinWorkspace.graphql')

const TalentPool = ({ talentPool, account_id }) => {
  const [toggleIsGuest] = useMutation(TOGGLE_IS_ACCOUNT_GUEST)
  const [updateRole] = useMutation(UPDATE_ROLE_IN_WORKSPACE)

  return (
    <div className="mt-5 flex flex-col">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Account members</h3>
      </div>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    ID
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>

                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Account Role
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Is guest
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {talentPool.map((person, index) => (
                  <tr key={`${person.type}-${person.id}-${index}`}>
                    <td className="px-6 py-4 whitespace-nowrap  text-xs font-semibold text-gray-600">{person.id}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <AvatarWithNameAndEmail person={person} />
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={classNames(
                          'px-3',
                          'py-1',
                          'rounded-full',
                          'text-xs',
                          'text-gray-100',
                          person.type === 'user' ? 'bg-green-400' : 'bg-yellow-400'
                        )}
                      >
                        {person.type === 'user' ? 'Active' : 'Pending'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <Select
                        title={person.role}
                        items={roles.map((role) => ({ title: role }))}
                        handleChange={async (role) =>
                          await updateRole({
                            variables: {
                              hash: person.hash,
                              account_id,
                              role
                            }
                          })
                        }
                      />
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <input
                        name={`${person.id}-is-guest`}
                        type="checkbox"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        defaultChecked={person.is_guest}
                        onChange={async () => {
                          await toggleIsGuest({
                            variables: {
                              user_id: person.id,
                              account_id
                            }
                          })
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TalentPool
