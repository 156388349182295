import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import dateformat from 'dateformat'
import { AvatarWithNameAndEmail } from 'components'
import Organizations from './Organizations'
import InvitesSent from './InvitesSent'

import { loader } from 'graphql.macro'
const USER = loader('./user.graphql')
const RECALCULATE_USER_SCORES = loader('./recalculateUserScores.graphql')

function ProfileComplete({ condition }) {
  const colors = {
    incomplete: 'bg-yellow-400',
    complete: 'bg-green-400'
  }

  return (
    <div className="flex items-center">
      <div
        className={` px-3
          py-1
          rounded-full
          text-xs
          text-gray-100
          ${colors[condition]}
        `}
      >
        {condition}
      </div>
    </div>
  )
}

export default function User() {
  const { id: user_id } = useParams()

  const { data, loading } = useQuery(USER, {
    variables: {
      user_id: parseInt(user_id, 10)
    }
  })
  const [recalculateUserScores, recalcState] = useMutation(RECALCULATE_USER_SCORES)

  if (loading) return null

  const { id, name, first_name, last_name, initials, email, avatar_url, registered, onboarding, profile_complete } =
    data.user

  return (
    <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            <AvatarWithNameAndEmail
              person={{
                id,
                name,
                first_name,
                last_name,
                initials,
                email,
                avatar_url
              }}
            />
          </h3>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">ID</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{id}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Registered</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{dateformat(registered, 'longDate')}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Email</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{email}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Onboarding</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {`${onboarding?.type?.name}${
                  !onboarding
                    ? ''
                    : onboarding?.skipped_at
                    ? `, skipped on ${dateformat(onboarding?.skipped_at, 'longDate')}`
                    : onboarding?.completed_at
                    ? `, completed on ${dateformat(onboarding?.completed_at, 'longDate')}`
                    : ', in progress'
                }`}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Profile</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <ProfileComplete condition={profile_complete ? 'complete' : 'incomplete'} />
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <button
        type="button"
        className="mt-5 mr-5 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={() => recalculateUserScores({ variables: { user_id: id } })}
        disabled={recalcState.called && recalcState.loading}
      >
        {recalcState.called && recalcState.loading ? 'Calculating...' : 'Recalculate profile'}
      </button>
      {recalcState.called &&
        !recalcState.loading &&
        recalcState.data?.recalculateUserScores?.success &&
        recalcState.data?.recalculateUserScores?.message}
      <Organizations />
      <InvitesSent />
    </main>
  )
}
