import { AvatarWithNameAndEmail } from 'components'
import { useHistory } from 'react-router-dom'

export default function Members({ members }) {
  const history = useHistory()
  return (
    <div className="mt-5 flex flex-col">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Team members
        </h3>
      </div>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {members.map((person, index) => (
                  <tr
                    key={`member-${person.id}`}
                    className=" cursor-pointer hover:bg-gray-50 transition ease-in-out duration-150"
                    onClick={() => history.push(`/user/${person.id}`)}
                  >
                    <td className="px-6 py-4 whitespace-nowrap  text-xs font-semibold text-gray-600">
                      {person.id}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <AvatarWithNameAndEmail person={person} />
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap"></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
