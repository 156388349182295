import { useState } from 'react'
import classNames from 'classnames'
import { AvatarWithNameAndEmail, Select } from 'components'
import { orgRoles as roles } from 'config/roles'
import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { TrashIcon } from '@heroicons/react/outline'
import DeletePrompt from './DeletePrompt'
const UPDATE_ROLE_IN_ORGANIZATION = loader('./updateRoleInOrganization.graphql')

const TalentPool = ({ talentPool, org_id }) => {
  const [updateRole] = useMutation(UPDATE_ROLE_IN_ORGANIZATION)
  const [deletePrompt, setDeletePrompt] = useState(false)

  return (
    <div className="mt-5 flex flex-col">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Talent Pool</h3>
      </div>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    ID
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>

                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Role
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {talentPool.map((person, index) => (
                  <tr key={`${person.type}-${person.id}`}>
                    <td className="px-6 py-4 whitespace-nowrap  text-xs font-semibold text-gray-600">{person.id}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <AvatarWithNameAndEmail person={person} />
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={classNames(
                          'px-3',
                          'py-1',
                          'rounded-full',
                          'text-xs',
                          'text-gray-100',
                          person.type === 'user' ? 'bg-green-400' : 'bg-yellow-400'
                        )}
                      >
                        {person.type === 'user' ? 'Active' : 'Pending'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <Select
                        title={person.role}
                        items={roles.map((role) => ({ title: role }))}
                        handleChange={(role) =>
                          updateRole({
                            variables: {
                              hash: person.hash,
                              org_id,
                              role
                            }
                          })
                        }
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm ">
                      <TrashIcon
                        className="-ml-1  h-5 w-5 text-gray-500 cursor-pointer hover:text-red-600"
                        onClick={() => setDeletePrompt(person)}
                      />
                    </td>
                  </tr>
                ))}
                <DeletePrompt open={!!deletePrompt} setOpen={setDeletePrompt} person={deletePrompt} org_id={org_id} />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TalentPool
