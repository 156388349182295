import { CalendarIcon } from '@heroicons/react/outline'
import dateformat from 'dateformat'
import { useHistory } from 'react-router-dom'

export default function Positions({ positions }) {
  const history = useHistory()
  const colors = {
    0: 'blue',
    1: 'green',
    2: 'purple',
    3: 'yellow',
    4: 'red'
  }

  const color = (name) => `bg-${colors[Array.from(name.charCodeAt(0).toString()).map(Number)[0] % 5]}-500`

  return (
    <div className="mt-5 flex flex-col">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Positions</h3>
      </div>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    ID
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>

                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Created at
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {positions.map((position, index) => (
                  <tr
                    key={`position-${index}`}
                    className="hover:bg-gray-50 cursor-pointer"
                    onClick={() => history.push(`/position/${position.id}`)}
                  >
                    <td className="px-6 py-4 whitespace-nowrap  text-xs font-semibold text-gray-600">{position.id}</td>
                    <td className="px-6 py-4 whitespace-nowrap  text-xs font-semibold text-gray-600">
                      <div className="flex items-center">
                        <div
                          className={`
                            h-7
                            w-7
                            flex
                            items-center
                            justify-center
                            rounded
                            uppercase
                            mr-2
                            text-white
                            font-medium
                            ${color(position.name)}
                            `}
                        >
                          {position.name[0]}
                        </div>
                        {position.name}
                      </div>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className=" flex-1 flex items-center text-xs font-semibold text-gray-600">
                        <CalendarIcon className="mr-2 flex-shrink-0 h-5 w-5 text-gray-300" />{' '}
                        {dateformat(position.created_at, 'longDate')}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
