import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import { Header } from 'components'
import Accounts from './Accounts'
import Account from './Account'
import Users from './Users'
import User from './User'
import Organizations from './Organizations'
import Organization from './Organization'
import Invites from './Invites'
import Invite from './Invite'
import Teams from './Teams'
import Team from './Team'
import Positions from './Positions'
import Position from './Position'

export default function Routes() {
  return (
    <Router>
      <div className="min-h-full">
        <Header />
        <Switch>
          <Route path="/" exact component={Accounts} />
          <Route path="/accounts" exact component={Accounts} />
          <Route path="/account/:id" exact component={Account} />

          <Route
            path="/organizations"
            exact
            component={Organizations}
          />
          <Route
            path="/organization/:id"
            exact
            component={Organization}
          />

          <Route path="/users" exact component={Users} />
          <Route path="/user/:id" exact component={User} />

          <Route path="/invites" exact component={Invites} />
          <Route path="/invite/:id" exact component={Invite} />

          <Route path="/teams" exact component={Teams} />
          <Route path="/team/:id" exact component={Team} />

          <Route path="/positions" exact component={Positions} />
          <Route path="/position/:id" exact component={Position} />
        </Switch>
      </div>
    </Router>
  )
}
