import React, { useState } from 'react'
import AddOrgModal from './AddOrgModal'
import AllowedProfilesModal from './AllowedProfilesModal'
import SeatsUsesOverTime from './SeatsUsesOverTime'
import TalenPool from './TalenPool'
import dateformat from 'dateformat'
import { useParams } from 'react-router-dom'
import { PlusIcon, PencilIcon, UserIcon } from '@heroicons/react/solid'
import { useQuery } from '@apollo/client'
import Organizations from './organizations'

import { loader } from 'graphql.macro'
const ACCOUNT = loader('./account.graphql')

export default function Account(props) {
  const { id } = useParams()
  const [addOrgModelIsOpen, toggleAddOrgModal] = useState(false)
  const [allowedProfilesModalIsOpen, toggleAllowedProfilesModal] = useState(false)
  const [seastUsedModalIsOpen, toggleSeasUsedModal] = useState(false)

  const { data, loading, refetch } = useQuery(ACCOUNT, {
    variables: {
      accountId: parseInt(id, 10)
    }
  })

  if (loading) return null
  const {
    id: account_id,
    name,
    talentPool,
    created_at,
    organizations,
    subscription: { type: subscriptionType, seatsUsed, allowedProfiles, billingUser }
  } = data.account

  return (
    <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">{name}</h3>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">ID</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{id}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Subscription type</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{subscriptionType}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">created at</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{dateformat(created_at, 'longDate')}</dd>
            </div>

            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Seats used</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {['free', 'custom'].includes(subscriptionType) ? `${seatsUsed} / ${allowedProfiles}` : seatsUsed}
              </dd>
            </div>
            {billingUser && (
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Billing user</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                      <img className="h-10 w-10 rounded-full" src={billingUser.avatar_url} alt="" />
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">{`${billingUser.first_name} ${billingUser.last_name}`}</div>
                      <div className="text-sm text-gray-500">{billingUser.contact_email}</div>
                    </div>
                  </div>
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>

      {['free', 'custom'].includes(subscriptionType) && (
        <button
          type="button"
          className="mt-5 mr-5 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => toggleAllowedProfilesModal(true)}
        >
          <PencilIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" />
          Edit allowed profiles
        </button>
      )}
      <button
        type="button"
        className="mt-5 mr-5 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={() => toggleAddOrgModal(true)}
      >
        <PlusIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" />
        Add org
      </button>

      <button
        type="button"
        className="mt-5 mr-5 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={() => toggleSeasUsedModal(true)}
      >
        <UserIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" />
        Seats used over time
      </button>

      <Organizations organizations={organizations} />
      <TalenPool talentPool={talentPool} account_id={account_id} />
      {addOrgModelIsOpen && (
        <AddOrgModal open={addOrgModelIsOpen} setOpen={toggleAddOrgModal} account={data.account} refetch={refetch} />
      )}
      {allowedProfilesModalIsOpen && (
        <AllowedProfilesModal
          open={allowedProfilesModalIsOpen}
          setOpen={toggleAllowedProfilesModal}
          account={data.account}
        />
      )}
      {seastUsedModalIsOpen && (
        <SeatsUsesOverTime open={seastUsedModalIsOpen} setOpen={toggleSeasUsedModal} account={data.account} />
      )}
    </main>
  )
}
