import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import dateformat from 'dateformat'
import { AvatarWithNameAndEmail } from 'components'
import { loader } from 'graphql.macro'
import Candidates from './Candidates'
import Invites from './Invites'
import { useHistory } from 'react-router-dom'
import getContentHtml from 'utils/getContentHtml'
const POSITION = loader('./position.graphql')

export default function User() {
  const { id: position_id } = useParams()
  const history = useHistory()

  const { data, loading } = useQuery(POSITION, {
    variables: {
      position_id: parseInt(position_id, 10)
    }
  })

  if (loading) return null
  const {
    id,
    name,
    created_at,
    created_by,
    candidates,
    invites,
    organization,
    team,
    objectives,
    requirements,
    competenciesHaveBeenConfed
  } = data.position

  const colors = {
    0: 'blue',
    1: 'green',
    2: 'purple',
    3: 'yellow',
    4: 'red'
  }
  const color = (name) => `bg-${colors[Array.from(name.charCodeAt(0).toString()).map(Number)[0] % 5]}-500`

  return (
    <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">ID</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{id}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{name}</dd>
            </div>

            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Created at</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{dateformat(created_at, 'longDate')}</dd>
            </div>

            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Created by</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {created_by && <AvatarWithNameAndEmail person={created_by} />}
              </dd>
            </div>

            <div
              className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 cursor-pointer hover:bg-gray-100"
              onClick={() => history.push(`/organization/${organization.id}`)}
            >
              <dt className="text-sm font-medium text-gray-500">Organization</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className=" flex items-center">
                  <div
                    className={`
                      h-7
                      w-7
                      flex
                      items-center
                      justify-center
                      rounded
                      uppercase
                      mr-2
                      text-white
                      font-medium
                      ${color(organization.name)}
                      `}
                  >
                    {organization.name[0]}
                  </div>
                  {organization.name}
                </div>
              </dd>
            </div>

            <div
              className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 cursor-pointer hover:bg-gray-100"
              onClick={() => history.push(`/team/${team.id}`)}
            >
              <dt className="text-sm font-medium text-gray-500">Team</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className=" flex items-center">
                  <div
                    className={`
                      h-7
                      w-7
                      flex
                      items-center
                      justify-center
                      rounded
                      uppercase
                      mr-2
                      text-white
                      font-medium
                      ${color(team.name)}
                      `}
                  >
                    {team.name[0]}
                  </div>
                  {team.name}
                </div>
              </dd>
            </div>

            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Competencies mapped</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>{`${competenciesHaveBeenConfed}`}</div>
              </dd>
            </div>

            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Objectives</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div dangerouslySetInnerHTML={getContentHtml(objectives)}></div>
              </dd>
            </div>

            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Requirements</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  {requirements.map(({ title, description }, i) => {
                    return (
                      <div key={`requirement-${i}`}>
                        <h2 className="text-base font-bold my-4 ">{title}</h2>
                        <div dangerouslySetInnerHTML={getContentHtml(description)}></div>
                      </div>
                    )
                  })}
                </div>
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <Candidates candidates={candidates} />
      <Invites invites={invites} />
    </main>
  )
}
