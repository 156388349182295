import React from "react";
import ReactDOM from "react-dom";
import Routes from "./routes";
import AuthProvider from "./AuthProvider";
import { ApolloProvider } from "@apollo/client";
import client from "./client";

import "./styles/output.css";

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <ApolloProvider client={client}>
        <Routes />
      </ApolloProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
